import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: "Sora";
  }

  section {
    
    &.padding-section {

        &:nth-child(n+2) {
        padding: 40px 0;

        @media ${device.tablet} {
          padding: 56px 0;
        }
        @media ${device.desktopLG} {
          padding: 64px 0;
        }
        @media ${device.desktopXL} {
          padding: 96px 0;
        }
      }
    }
  }
`
