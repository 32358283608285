import React from 'react'

import { Wrapper } from './style'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from './sections/_hero/_index'
import SuaVidaFinanceira from './sections/_sua-vida-financeira/_index'
import FAQSection from './sections/_faq/_index'
import FooterUs from 'src/components/Us/FooterUS/_index'

const PrivacidadeDeDados = () => {
  const lang = 'pt'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <SuaVidaFinanceira lang={lang} />
        <FAQSection lang={lang} pageContext={pageContext} />
        <FooterUs section='dobra_04' slugPT={pageContext.slug} slugEN={pageContext.pageEN} bgColor='bg-grayscale--500' lang='pt' />
      </Layout>
    </Wrapper>
  )
}

export default PrivacidadeDeDados
